import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery, Link } from 'gatsby'

import footerAngleSVG from '../img/footer-angle-bg.svg'

if (typeof window !== 'undefined') {
  document.addEventListener('contextmenu', (e) => {
    if (e.target.tagName === 'IMG') {
      e.preventDefault()
      e.stopPropagation()
    }
  })
}

export const FooterTemplate = ({
  location,
  missionstatement,
  servicesummary,
}) => {
  return (
    <div className="w-100 gc1 grid helvetica footer-bp">
      <div
        className="gcs1 gce2 grs1 gre2 z-1 bg-100wh"
        style={{ backgroundImage: `url(${footerAngleSVG})` }}
      ></div>{' '}
      <div className="gcs1 gce2 grs1 gre2 pt5-ns pt3 g-self-end z-2">
        <div className="w-100 ph5-ns ph1 pb3 tc f6-ns f7 tracked-ns lh-copy">
          
          <div className="center pb2">
            {/* <a
              className="link center z-2"
              href="mailto:magdalena@lenaski.com"
              title="Email"
              target="_blank"
            >
              <Img
                className="gcs3 gce4 grs1 gre2 h1 w1 z-2"
                alt="Book a consultation"
                fluid={data.emailImage.childImageSharp.fluid}
              />
            </a> */}
            <a
              className="link hover-green gray dib h1 w1 mr3"
              href="https://za.linkedin.com/in/lenaski"
              title="LinkedIn"
              target="_blank"
            >
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.414"
              >
                <path
                  d="M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964-1.424 1.96v3.778h-2.37V6H8.51V7.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758.614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377zm1.188 8.68H2.37V6h2.376v7.635zM14.816 0H1.18C.528 0 0 .516 0 1.153v13.694C0 15.484.528 16 1.18 16h13.635c.652 0 1.185-.516 1.185-1.153V1.153C16 .516 15.467 0 14.815 0z"
                  fillRule="nonzero"
                />
              </svg>
            </a>

            <a
              className="link hover-green gray dib h1 w1"
              href="https://www.instagram.com/lena_ski_supernova"
              title="Instagram"
              target="_blank"
            >
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.414"
              >
                <path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.048 3.233c-.036.78-.166 1.203-.276 1.485-.145.374-.318.64-.598.92-.28.28-.546.453-.92.598-.282.11-.705.24-1.485.276-.844.038-1.097.047-3.233.047s-2.39-.01-3.233-.048c-.78-.036-1.203-.166-1.485-.276-.374-.145-.64-.318-.92-.598-.28-.28-.453-.546-.598-.92-.11-.282-.24-.705-.276-1.485C1.45 10.39 1.44 10.136 1.44 8s.01-2.39.048-3.233c.036-.78.166-1.203.276-1.485.145-.374.318-.64.598-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276C5.61 1.45 5.864 1.44 8 1.44zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z" />
              </svg>
            </a>

            <a
              className="link hover-green gray dib h1 w1 ml3"
              href="https://www.facebook.com/Lena.Ski.SuperNova"
              title="Facebook"
              target="_blank"
            >
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fillRule="evenodd"
                clipRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="1.414"
              >
                <path
                  d="M15.117 0H.883C.395 0 0 .395 0 .883v14.234c0 .488.395.883.883.883h7.663V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.476-1.195 1.176v1.54h2.39l-.31 2.416h-2.08V16h4.077c.488 0 .883-.395.883-.883V.883C16 .395 15.605 0 15.117 0"
                  fillRule="nonzero"
                />
              </svg>
            </a>
          </div>
          <Link className={'link pointer dim fw2'} to="/privacy-policy">
            Privacy Policy
          </Link>
          <div className="fw6 tracked-mega-ns tracked ski-dark-blue pt2">
            {missionstatement}
          </div>
          <div className="fw6">{servicesummary}</div>
          <div className="fw3">{location}</div>
        </div>
      </div>
    </div>
  )
}

FooterTemplate.propTypes = {
  location: PropTypes.string,
  missionstatement: PropTypes.string,
  servicesummary: PropTypes.string,
}

const FooterWithData = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <FooterTemplate
      location={frontmatter.location}
      missionstatement={frontmatter.missionstatement}
      servicesummary={frontmatter.servicesummary}
    />
  )
}

FooterWithData.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      query Footer {
        markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
          frontmatter {
            location
            missionstatement
            servicesummary
          }
        }
      }
    `}
    render={(data) => <FooterWithData data={data} />}
  />
)

export default Footer
