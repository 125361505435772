import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../templates/footer'
import Navbar from '../components/Navbar'
import '../style/skye.css'
import '../style/tachyons.css'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children, seo, page }) => {
  const { title, description, siteUrl } = useSiteMetadata()
  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: `en`
        }}
        title={
          seo === undefined || seo === null ? title
            : seo.title === '' || seo.title === undefined || seo.title === null
                ? title
                : seo.title
        }
        meta={[
          {
            name: `description`,
            content:
              seo === undefined || seo === null ? description :
                seo.description === '' ||
                seo.description === undefined ||
                seo.description === null
                  ? description
                  : seo.description
          },
          {
            property: `og:title`,
            content:
              seo === undefined || seo === null ? title
              : seo.title === '' || seo.title === undefined || seo.title === null
                ? title
                : seo.title
          },
          {
            property: `og:description`,
            content:
              seo === undefined || seo === null ? description :
                seo.description === '' ||
                seo.description === undefined ||
                seo.description === null
                  ? description
                  : seo.description
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: `og:image`,
            content: `https://www.lenaski.com/img/Lena_Ski_Business_Coach.jpg`
          },
          {
            property: `og:url`,
            content: siteUrl,
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          // {
          //   name: `twitter:creator`,
          //   content: site.siteMetadata.author
          // },
          {
            name: `twitter:title`,
            content:
            seo === undefined || seo === null ? title
              : seo.title === '' || seo.title === undefined || seo.title === null
                  ? title
                  : seo.title
          },
          {
            name: `twitter:description`,
            content:
              seo === undefined || seo === null ? description :
                seo.description === '' ||
                seo.description === undefined ||
                seo.description === null
                  ? description
                  : seo.description
          }
        ]
          .concat(
            seo === null || seo === undefined
              ? []
              : seo.keywords === null || seo.keywords === undefined
                  ? []
                  : seo.keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: seo.keywords.join(`, `)
                    }
                  : []
          )}
      >
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/lena-ski-favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/lena-ski-favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        
        <link
          href="https://fonts.googleapis.com/css?family=Yellowtail&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar page={page} />
      <div className="helvetica body-box">{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
