import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { isEmpty } from 'lodash-es'

const isEmtpy = (a) => {
  return a === null || a === undefined
}

const pages = {
  portfolio: 'portfolio',
  podcast: 'podcast',
  contact: 'contact',
  leadyourwork: 'leadyourwork'
}

const NavbarTemplate = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      navBarItemClass:
        'link pointer flex-ns db items-center flex-shrink-0 flex-grow-0 ph3 pv2-ns pv3 relative',
      isTop: true,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  handleScroll = (event) => {
    try {
      this.setState({
        isTop: event.srcElement.lastElementChild.scrollTop < 5,
      })
    } catch (error) {
      this.setState({
        isTop: true,
      })
    }
  }

  render() {
    return (
      <>
        <div
          className={
            'z-9998 bg-black-40 fixed left-0 right-0 top-0 bottom-0 dn-ns ' +
            (this.state.navBarActiveClass === '' ? 'dn' : 'db')
          }
          onClick={() => this.toggleHamburger()}
        />
        <nav
          className={
            'navbox bottom-0-s ph5-ns ph0 fixed w-100 items-stretch flex z-9999 helvetica ' +
            (this.state.isTop
              ? 'bg-ski-white-s shadow-1-s '
              : 'shadow-1 bg-ski-white-60-ns bg-ski-white ')
          }
          role="navigation"
          aria-label="main-navigation"
        >
          <div className="flex items-stretch w-100-s flex-grow-1 mv0 center relative">
            <div className="items-stretch ph0-ns ph3 flex flex-shrink-0 w-100-s nl2-ns">
              <Link to="/" className={this.state.navBarItemClass} title="Logo">
                {this.props.logoImageData === null ? null : (
                  <>
                    <Img
                      fluid={
                        this.props.logoImageData.logoImage.childImageSharp.fluid
                      }
                      className={"db-ns dn " + ((isEmpty(this.props.page) ||
                      isEmpty(pages[this.props.page])) &&
                      this.state.isTop ? 'o-0' : '') }
                      alt="Lens Ski Business Logo"
                      style={{ width: '220px' }}
                    />
                    <Img
                      fluid={
                        this.props.logoImageData.logoImage.childImageSharp.fluid
                      }
                      className="dn-ns db"
                      alt="Lens Ski Business Logo"
                      style={{ width: '160px' }}
                    />
                  </>
                )}
              </Link>
              {/* Hamburger menu */}
              <div className="relative grid gai-center flex-row-reverse ml-auto right-0">
                <div
                  data-target="navMenu"
                  className={
                    'vegiburger js-vegiburger ' + this.state.navBarActiveClass
                  }
                  onClick={() => this.toggleHamburger()}
                >
                  <div className="vegiburger-box">
                    <div className="vegiburger-inner" />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="navMenu"
              className={`flex-row-reverse dn items-stretch flex-ns flex-grow-1 flex-shrink-0 nr2 no-shadow-force z-max vegimenu ${this.state.navBarActiveClass}`}
            >
              <div className="tr-ns tc justify-end-ns gray mra items-stretch flex-ns grid">
                <Link
                  className={
                    this.state.navBarItemClass +
                    ' grs1 gre2 ' +
                    (this.props.page === 'portfolio'
                      ? 'ski-medium-green'
                      : 'ski-black')
                  }
                  to="/portfolio"
                >
                  Portfolio
                </Link>
                <Link
                  className={
                    this.state.navBarItemClass +
                    ' grs2 gre3 ' +
                    (this.props.page === 'podcast'
                      ? 'ski-medium-green'
                      : 'ski-black')
                  }
                  to="/podcast"
                >
                  Podcast
                </Link>
                <Link
                  className={
                    this.state.navBarItemClass +
                    ' grs3 gre4 ' +
                    (this.props.page === 'leadyourwork'
                      ? 'ski-medium-green'
                      : 'ski-black')
                  }
                  to="/leadyourwork"
                >
                  Lead Your Work
                </Link>
                {/* <Link className={this.state.navBarItemClass} to="/blog">
                Blog
              </Link> */}
                <Link
                  className={
                    this.state.navBarItemClass +
                    ' grs4 gre5 lastlink ' +
                    (this.props.page === 'contact'
                      ? 'ski-medium-green'
                      : 'ski-black')
                  }
                  to="/contact"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

const Navbar = ({ page }) => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(
        relativePath: { eq: "Lena Ski Purpose Strategist Logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            aspectRatio
            src
            srcSet
            sizes
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  `)

  return <NavbarTemplate logoImageData={data} page={page} />
}

export default Navbar
